import { fontSizes } from '@cash-me/web-tokens'
import styled from 'styled-components'

import { Section } from 'components/general-styles'
import { theme } from 'styles/theme'

const { borderWidths, colors, media, space } = theme

export const Blog = styled(Section)`
  width: 100%;

  button {
    margin-top: ${space['spacing-sm']};
  }
`

export const Post = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${space['spacing-sm']};
  gap: ${space['spacing-sm']};
  border: ${borderWidths['border-stroke-micro']} solid ${colors['neutral-border-default']};
  border-radius: ${space['spacing-xxs']};

  > p {
    font-size: 15px;
  }

  @media (${media.desktop}) {
    font-size: ${fontSizes['font-size-body-2']};
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 2.375rem;

  > div {
    position: relative;
    width: 100%;
    max-width: 6.25rem;
  }
`
